import { Fragment, FunctionalComponent, h } from "preact";

import { ResumeLinks } from "../lib/resume_links";
import { FontAwesomeLink } from "./font-awesome-link";
import { DisplayTitle } from "./shared_props";

import "../styles/theme.css";
import "../styles/headers.css";

type Props = DisplayTitle;

export const PortfolioLinks: FunctionalComponent<Props> = ({ displayTitle }: Props) => (
  <Fragment>
    {displayTitle && <h2 className="h3">Links</h2>}
    <ul className="noBullets">
      {ResumeLinks.map((props, index) => (
        <FontAwesomeLink {...props} key={index} />
      ))}
    </ul>
  </Fragment>
);

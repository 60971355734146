import { IconProp, IconName, IconLookup, icon as renderIcon } from "@fortawesome/fontawesome-svg-core";
import { FunctionalComponent, h } from "preact";
import "../styles/font-awesome.css";

interface Props {
  linkText: string;
  icon: IconProp;
  link: string;
}

const convertIcon = (icon: IconProp): IconName | IconLookup => {
  if (typeof icon === "string") return icon as IconName;
  if (Array.isArray(icon)) {
    const [prefix, iconName] = icon;
    return { prefix, iconName };
  }
  return icon;
};

const convertD = (d: string | string[]): string => (typeof d === "object" ? d.join(" ") : d);

export const FontAwesomeLink: FunctionalComponent<Props> = ({ linkText, icon, link }: Props) => {
  const rendered = renderIcon(convertIcon(icon));
  return (
    <li key={link}>
      <svg className="fa-width" viewBox={`0 0 ${rendered.icon.slice(0, 2).join(" ")}`}>
        <path d={convertD(rendered.icon[4])} />
      </svg>
      &nbsp;
      <a href={link}>{linkText}</a>
    </li>
  );
};

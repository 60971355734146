import { FunctionalComponent, h } from "preact";
import { useEffect } from "preact/hooks";

export const Resume: FunctionalComponent = () => {
  useEffect(() => {
    location.href = "/assets/resume.pdf";
  });

  return <div />;
};

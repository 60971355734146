import { FunctionalComponent, h } from "preact";
import { Link } from "preact-router";

export const NotFound: FunctionalComponent = () => (
  <div className="main">
    <main className="main">
      <h1>
        404: <i>Not Found</i>
      </h1>
      <p>
        Go back to the <Link href="/">home page</Link>?
      </p>
    </main>
  </div>
);

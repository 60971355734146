import { h, FunctionalComponent } from "preact";
import Router from "preact-router";

import { Home, NotFound, Resume } from "./pages";

import "./styles/theme.css";
import "./styles/globals.css";

const Index: FunctionalComponent = () => (
  <Router>
    <Home path="/" />
    <Resume path="assets/resume.pdf" />
    <NotFound default />
  </Router>
);

export default Index;

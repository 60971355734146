import { Fragment, FunctionalComponent, h } from "preact";

import { ContactInformation } from "../lib/contact_information";
import { FontAwesomeLink } from "./font-awesome-link";
import { DisplayTitle } from "./shared_props";

import "../styles/theme.css";
import "../styles/headers.css";

type Props = DisplayTitle;

export const ContactMe: FunctionalComponent<Props> = ({ displayTitle }: Props) => (
  <Fragment>
    {displayTitle && <h2 className="h3">Contact Me</h2>}
    <ul className="noBullets">
      {ContactInformation.map((props, index) => (
        <FontAwesomeLink {...props} key={index} />
      ))}
    </ul>
  </Fragment>
);
